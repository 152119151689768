body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  background: #f7f5ff;
}

/* Top section with header and logo*/


.header {
  padding-top: 55px;
  margin: 0px 24px;
}

.header-image {
  display: flex;
  justify-content: center;
}

.profile-picture {
  border-radius: 50%;
  width: 164px;
}

.header-text {
  padding-top: 10px;
  padding-bottom: 20px;
}

.frida {
  padding-top: 29px;
  font-family: "Satoshi", sans-serif;
  font-size: 28px;
  color: blue;
}

.job-title {
  font-family: "Satoshi", sans-serif;
  font-weight: 900;
  font-size: 28px;
}

.extra-job-title {
  font-family: "Satoshi", sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #565656;
}

.presentation {
  font-family: "EB Garamond", serif;
  font-size: 22px;
  margin-top: 22px;
}

.logo-container {
  display: none;
}

.sm-icon {
  padding-top: 15px;
  width: 32px;
}

.socialmedia-icons {
  display: flex;
  justify-content: space-evenly;
  padding-top: 12px;
}

.sm-icon:hover {
  filter: brightness(40%);
}

.project-image:hover {
  transform: scale(1.1);
}

.arrow-section {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  width: 50px;
  animation: bounce 2s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(50px);
  }
}

/* Tech Section */

.tech-section {
  display: flex;
  background-color: #e4f4e3;
  flex-direction: column;
}

.tech-title {
  font-family: "Satoshi", sans-serif;
  font-size: 80px;
  font-weight: 700;
  color: blue;
  margin: 99px 0px 0px 0px;
  text-align: center;
}

.tech-text {
  font-family: "EB Garamond", serif;
  font-size: 22px;
  font-weight: 400;
  margin: 24px;
  padding-bottom: 50px;
}

.tech-word {
  animation: color-animation 4s linear infinite;
}

.highlight-1 {
  --color-1: #637aea;
  --color-2: #df89f4;
  --color-3: #637aea;
}

.highlight-2 {
  --color-1: #05def2;
  --color-2: #f18ee2;
  --color-3: #05def2;
}

.highlight-3 {
  --color-1: #65dc8d;
  --color-2: #e870ee;
  --color-3: #65dc8d;

}

.highlight-4 {
  --color-3: #df2ae2;
  --color-2: #2fee6f;
  --color-3: #df2ae2;
  ;
}

@keyframes color-animation {

  32% {
    color: var(--color-1)
  }

  33% {
    color: var(--color-2)
  }

  66% {
    color: var(--color-3)
  }

}

/* Projects section */

.projects-section {
  display: flex;
  flex-direction: column;
  margin: 75px 24px;
  gap: 2.5em;
}

.section-title {
  display: flex;
  flex-direction: column;
  font-family: "Satoshi", sans-serif;
  font-size: 72px;
  font-weight: 700;
  color: blue;
}

.featured {
  align-self: center;
}

.project-image {
  width: 100%;
  height: 280px;
  object-fit: cover;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.project-title {
  display: flex;
  font-family: "Satoshi", sans-serif;
  font-weight: 900;
  font-size: 32px;
  color: black;
  margin-top: 20px;
}

.project-text {
  font-size: 22px;
  font-weight: 400;
  color: black;
  font-family: 'EB Garamond', 'sans serif';
}

.project-text-and-icons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.project-text-group {
  display: flex;
  flex-direction: column;
  gap: 5px;

}

.language-buttons {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 12px;
  line-height: 0.8;
}

.language-text {
  background-color: black;
  font-family: "Satoshi", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: white;
  padding: 5px;
  margin-top: 10px;
}

.github-link-project,
.github-link-project-FE,
.live-link-project {
  display: flex;
  gap: 7px;
  align-items: center;
  font-family: "Satoshi", sans-serif;
  font-weight: 500;
  font-size: 16px;
  background-color: #eaf6f6;
  padding: 0px 16px 0px 0px;
  margin-top: 5px;
  border: none;
  border-radius: 40px;
  height: 40px;
}

.github-link-project:hover,
.github-link-project-FE:hover {
  background-color: hotpink;
  color: white;
  background-image: white;
  cursor: pointer;
}


.live-link-project:hover {
  background-color: blue;
  color: white;
  background-image: white;
  cursor: pointer;
}

/* Skills section */

.skills-title {
  font-family: "Satoshi", sans-serif;
  font-weight: 700;
  font-size: 72px;
  text-align: center;
  padding: 40px;
  color: blue;
}

.skills-section {
  display: flex;
  flex-direction: column;
  background: #e4f4e3;
  padding: 40px;
}

.skills-list {
  font-family: "EB Garamond", serif;
  font-size: 22px;
  font-weight: 400;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
  list-style-type: none;
}

.code {
  background: hotpink;
  color: white;
}

.toolbox {
  background: blue;
  color: white;
}

.more {
  background: lightseagreen;
  color: white;
}

.upcoming {
  background: green;
  color: white;
}

/* My words section */

.my-words-section {
  flex-direction: column;
  display: flex;
  padding: 64px 24px;
  background: #eaf6f6;
}

.mw-section-title {
  font-family: "Satoshi", sans-serif;
  font-size: 72px;
  font-weight: 700;
  color: #eb5577;
  text-align: center;
  padding-bottom: 48px;
}

.date-text {
  font-family: "Satoshi", sans-serif;
  color: #eb5577;
  font-weight: 900;
  padding-top: 20px;
}

.blog-image {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.read-more-button {
  display: flex;
  gap: 7px;
  align-items: center;
  font-family: "Satoshi", sans-serif;
  font-weight: 500;
  font-size: 16px;
  background-color: white;
  padding: 0px 16px 0px 9px;
  margin-top: 10px;
  border: none;
  border-radius: 40px;
  height: 48px;
}

.read-more-button:hover {
  background: yellow;
}

/* Footer section */

.footer-section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 24px;
}

.footer-image {
  display: flex;
  justify-content: center;
  gap: 29px;
}

.footer-title {
  font-family: "Satoshi", sans-serif;
  font-size: 58px;
  font-weight: 700;
  color: #eb5577;
  text-align: center;
  padding-bottom: 30px;
}

.contact-wrapper {
  display: flex;
  flex-direction: column;

}

.contact-details {
  margin-left: 10px;
}

.contact-card {
  padding: 10px;
}

.contact-info {
  font-family: "Satoshi", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.socialmedia-icons-footer {
  display: flex;
  justify-content: space-evenly;
  gap: 29px;
  margin-top: 10px;
}

/* Media queries */

/* Tablet */

@media screen and (min-width: 667px) and (max-width: 1024px) {
  .logo-container {
    display: flex;
    height: 412px;
    background: #b69fdf;
    justify-content: center;
  }

  .logo {
    flex-basis: fit-content;
  }

  .header {
    display: flex;
    flex-direction: column;
    margin: 0px 102px;
  }

  .header-image-and-text {
    display: flex;
    flex-direction: row;
    gap: 29px;
  }

  .header-image {
    display: block;
  }

  .job-title {
    font-size: 32px;
  }

  .header-text {
    text-align: start;
  }

  .presentation {
    line-height: 29px;
  }

  .socialmedia-icons {
    align-items: flex-end;
    justify-content: flex-start;
    gap: 32px;
    padding: 40px 0px;
  }

  .arrow-section {
    align-items: flex-end;
    height: 20px;
  }

  .arrow-section {
    border: none;
    opacity: 0;
  }

  .tech-text {
    text-align: start;
    margin: 122px;
    line-height: 32px;
  }

  .projects-section {
    gap: 4rem;
  }

  .project {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 280px;
    margin-bottom: 40px;
  }

  .project-title {
    margin-top: 0;
  }

  .project-image {
    flex-basis: 20%;
    height: 280px;
    width: 187px;
    object-fit: cover;
  }

  .project-text-and-icons {
    padding-left: 24px;
    height: 280px;
    width: 70vw;
    justify-content: space-between;
  }

  .link-buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 24px;
  }

  .github-link-project,
  .github-link-project-FE,
  .live-link-project {

    margin-top: 5px;
    height: 40px;
  }

  .blog-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin: auto 60px;
  }

  .footer-title {
    font-size: 120px;
    font-weight: 900px;
  }

  .contact-card {
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  .contact-wrapper {
    display: grid;
    justify-content: center;
  }

  .contact-details {
    text-align: center;
    margin: 40px 0;

  }

  .contact-info {
    font-size: 32px;
    font-weight: 900;
  }

  .socialmedia-icons-footer {
    display: flex;
    justify-content: center;
    gap: 32px;
    margin: 168px 0px;
  }
}

/* Desktop */

@media (min-width: 1025px) {
  .top-section {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }

  .header {
    display: flex;
    flex-basis: 50%;
    height: 100vh;
    margin: 0px;
    justify-items: center;
    align-items: center;
  }

  .header-content {
    justify-content: center;
  }

  .header-image-and-text {
    display: flex;
    flex-direction: row;
    gap: 29px;
    justify-content: center;
  }

  .header-image {
    display: block;
  }

  .header-text {
    text-align: start;
  }

  .presentation {
    line-height: 29px;
    margin-left: 10%;
    margin-right: 5%;
  }

  .socialmedia-icons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 32px;
    padding: 40px 0px;
    margin-left: 10%;
  }

  .logo-container {
    display: flex;
    flex-basis: 50%;
    justify-content: center;
  }

  .job-title {
    font-size: 32px;
  }

  .logo {
    display: flex;
    width: 100%;
    background-color: #b69fdf;
  }

  .arrow-section {
    height: 0vh;
  }

  .arrow {
    filter: brightness(50%);
  }

  .tech-title {
    flex-basis: 30%;
    margin: 0%;
  }

  .tech-section {
    display: flex;
    height: 800px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .tech-text {
    flex-basis: 40%;
    margin: 0px;
    line-height: 32px;
  }

  .section-title {
    display: flex;
    flex-direction: column;
    font-size: 80px;
  }

  .featured {
    align-self: center;
  }

  .project {
    display: flex;
    justify-content: center;
  }

  .projects-section {
    gap: 100px;
  }

  .project-image {
    height: 280px;
    width: 280px;
  }

  .project-title {
    margin-top: 0;
  }

  .project-text {
    line-height: 29px;
  }

  .project-text-and-icons {
    padding-left: 24px;
    height: 280px;
    width: 42vw;
    justify-content: space-between;
  }

  .link-buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 24px;
    width: max-content;
  }

  .footer-title {
    font-size: 120px;
    font-weight: 900px;
  }

  .contact-card {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
  }

  .contact-wrapper {
    display: grid;
    justify-content: center;
  }

  .contact-details {
    text-align: center;
    padding: 80px;

  }

  .contact-info {
    font-size: 32px;
    font-weight: 900;
  }

  .socialmedia-icons-footer {
    display: flex;
    justify-content: center;
    gap: 32px;
  }

  .blog-container {
    display: flex;
    flex-direction: row;
    gap: 70px;
    margin: auto 60px;
  }

  .mw-section-title {
    text-align: start;
    margin-left: 84px;
    padding-bottom: 79px;
    font-size: 80px;
  }

  .blog-image {
    height: 340px;
    object-fit: cover;
    width: 100%;
  }

  .date-text {
    padding-top: 40px;
    font-size: 20px;
  }

  .blog-text-group {
    display: flex;
  }

  .skills-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 66px;
  }

  .skills-section {
    padding: 70px 40px;
  }

  #codeList,
  #moreList,
  #toolboxList,
  #upcomingList {
    flex-direction: column;
    text-align: start;
  }

  .code,
  .more,
  .toolbox,
  .upcoming {
    font-family: Satoshi, sans-serif;
    font-size: 24px;
    font-weight: 700;
  }

  .skills-title {
    font-size: 80px;
  }
}

@media (min-width: 1600px) {

  .socialmedia-icons {
    margin-left: 20%;
  }

  .presentation {
    margin-left: 20%;
  }
}